<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner title="CLIENTS" :breadcrumb="[
          { label: 'Dashboard' },
          { label: 'Client' }
        ]">
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>


        <div class="content-body" style="margin-top: -45px">
          <!-- users list start -->
          <section>
            <div class="row">
              <div class="col-xl-12 col-md-12">
                <div class="card custom-card">

                  <div class="card-body">



                    <div class="row">
                  <div class="col text font">
                    <h4><strong class="text">Clients</strong></h4>
                  </div>
                  <div class="col text-end cus-p-1">
                    <button title="Add New" data-bs-toggle="modal" data-bs-target="#panVerifiy" @click="valueNull()"
                        class="btn btn-sm text-white" style="background-color: #f21000">
                        <strong>New</strong>
                      </button>  &nbsp;
                <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>
                          &nbsp;

                  </div>
                    <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" >

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row ">
                  <div class="d-md-none d-xl-none d-block col-3 text font cus-p-1">

                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                    <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>
               
                </div>




                    <!-- start list area  -->
                    <div class="row">



<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" >
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm">
                          <tr class="text table-wrapper-scroll-y rounded-circle tr-head" style="background-color: white">
                            <th class="text-truncate" style="
                                background-color: #cdcdcd;
                                border-radius: 8px 0px 0px 8px;
                              ">
                              Code
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Name
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Client Pan
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Business Name
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Email
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Mobile No.
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Invoice
                            </th>

                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Due Balance
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Kyc Status
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Status
                            </th>
                            <th class="text-truncate text-end" style="
                                background-color: #cdcdcd;
                                border-radius: 0px 8px 8px 0px;
                              ">
                              Action
                            </th>
                          </tr>

                          <tbody class="text">
                            <tr v-for="(client, index) in clients" :key="index">
                              <td class="text-truncate">{{ client.code }}</td>
                              <td class="text-truncate">
                                {{ client.fname }} {{ client.lname }}
                              </td>
                              <td class="text-truncate">
                                {{ client.pan }}
                              </td>
                              <td class="text-truncate">
                                {{ clientBusinessName(client.business_clients) }}
                              </td>
                              <td class="text-truncate">{{ client.email }}</td>
                              <td class="text-truncate">+91-{{ client.phone }}</td>
                              <td class="text-truncate">Rs.

                                <span v-if="client.clientServiceTotal">
                                  {{
                                  parseInt(client.clientServiceTotal) +
                                  parseInt(client.clientServiceGst
)
                                }}
                                </span>
                                <span v-else>
                                  0
                                </span>
                              </td>
                              <td class="text-truncate">
                                Rs.
                                <span v-if="client.clientPaymentTotal">
                                  {{parseInt(client.clientServiceTotal) +
                                  parseInt(client.clientServiceGst) - parseInt(client.clientPaymentTotal) }}
                                </span>
                                <span v-else>
                                  0
                                </span>

                              </td>
                              <td style="padding: 0px 15px 0px 15px" class="text-truncate">
                                <span v-if="client.client_kyc">
                                  <p v-if="client.client_kyc.kycStatus ==
                                    'approve'
                                    " class="text-white btn btn-sm" style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                    <b>
                                      {{
                                        client.client_kyc.kycStatus
                                      }}</b>
                                  </p>

                                  <p v-if="client.client_kyc.kycStatus ==
                                      'cancel'
                                      " class="text-white btn btn-sm" style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                    <b>
                                      {{
                                        client.client_kyc.kycStatus
                                      }}</b>
                                  </p>

                                  <p v-if="client.client_kyc.kycStatus ==
                                      'reject'
                                      " class="text-white btn btn-sm" style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                    <b>
                                      {{
                                        client.client_kyc.kycStatus
                                      }}</b>
                                  </p>

                                  <p v-if="client.client_kyc.kycStatus ==
                                      'false'
                                      " class="text-white btn btn-sm" style="
                                    background-color: rgb(235, 133, 16);
                                    width: 70px;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                    <b>Pending</b>
                                  </p>
                                </span>
                                <span v-else>
                                  <p class="text-white btn btn-sm" style="
                                    background-color: rgb(235, 133, 16);
                                    width: 70px;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                    <b>Pending</b>
                                  </p>
                                </span>
                              </td>

                              <td class="text-truncate" v-if="client">
                                <p v-if="client.loginStatus == 'true'" class="btn btn-sm" style="
                                    width: 60px;
                                    background-color: green;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                  <b>Active</b>
                                </p>
                                <p v-else class="btn btn-sm" style="
                                    width: 60px;
                                    background-color: #f21000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                  <b>InActive</b>
                                </p>
                              </td>

                              <td class="text-truncate text-end">
                                <div class="btn-group btn-group-sm" role="group" aria-label="...">
                                  <router-link title="View" :to="`clientprofile/${client.id}`"
                                    class="btn btn-sm text-white" style="
                                      font-size: 8pt;
                                      width: 25px;
                                      background-color: #00364f;
                                      padding: 5px 5px 5px 5px;
                                    ">
                                    <font-awesome-icon icon="eye" />
                                  </router-link>
                                  <button title="Edit" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                    type="button" class="btn btn-sm btn-success"
                                    style="width: 25px; padding: 5px 5px 5px 5px" @click="valueAssignClient(client)">
                                    <font-awesome-icon icon="edit" />
                                  </button>
                                    <span v-if="client.client_kyc">
<button v-if="client.client_kyc.kycStatus 
                                  !=
                                    'approve'" title="Kyc" data-bs-toggle="modal" data-bs-target="#kycModal" type="button"
                                    class="btn btn-sm" style="
                                      font-size: 8pt;
                                      width: 25px;
                                      background-color: yellow;
                                      padding: 5px 5px 5px 5px;
                                    " @click="valueAssignClientForKyc(client)">
                                    <font-awesome-icon icon="file" />
                                  </button>
                                    </span>
                                  
                                  <!-- <router-link
                                  title="Client Payments"
                                    :to="`/retailer/payment/${client.id}`"
                                    class="btn btns btn-sm text-center text-white"
                                    style="
                                      width: 25px;
                                      font-size: 8pt;
                                      padding: 5px 5px 5px 5px;
                                      padding-left: 7px;
                                      background-color: #008000;
                                    "
                                  >
                                    <font-awesome-icon icon="file" />
                                  </router-link> -->
                                  <!-- <button
                                      type="button"
                                      class="btn btn-sm text-white"
                                      style="
                                        background-color: #f21300;
                                        padding: 5px 4.5px 5px 4.5px;
                                      "
                                      @click="deleteClient(client.id)"
                                    >
                                      <font-awesome-icon icon="trash" />
                                    </button> -->

                                  <!-- <button
                                  title="Earning"
                                    @click="leadClientServicesForClient(client)"
                                    data-bs-toggle="modal"
                                    data-bs-target="#erningmoneyModal"
                                    type="button"
                                    class="btn btn-sm text-white"
                                    style="
                                      font-size: 8pt;
                                      width: 25px;
                                      background-color: black;
                                      padding: 5px 5px 5px 5px;
                                    "
                                  >
                                    ₹
                                  </button> -->
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  </div>

                 
                  <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadClients(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadClients(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadClients(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadClients(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadClients(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadClients(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadClients(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadClients(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadClients(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadClients(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadClients(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadClients(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- model  -->
  <!-- Button trigger modal -->

  <!-- Modal -->
  <div class="modal fade" id="panVerifiy" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text" id="staticBackdropLabel">
            <strong>Pan Verify</strong>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <form id="formId" @submit.prevent="findRecordeByPanId">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label>Enter Pan</label>
                <input type="tel" maxlength="10" minlength="10" required v-model="panNo" class="form-control"
                  placeholder="Pan" style="text-transform: uppercase" />
              </div>

              <div v-if="exist == 'true'" class="col-xl-12 col-lg-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <img style="width: 35px" src="/assets/image/tick.png" alt="" />
                &nbsp;&nbsp;&nbsp;
                <span> This client is Alredy Exist</span>
              </div>
              <div v-if="exist == 'false'"></div>
              <div v-if="veryfied == 'invalid'" class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px">
                <img style="width: 35px" src="/assets/image/coss.png" alt="" />
                &nbsp;&nbsp;&nbsp;
                <span> Please Enter Valid Pan</span>
              </div>
              <div v-if="veryfied == 'false'"></div>

              <div class="modal-footer">
                <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                  <b> <font-awesome-icon icon="check" /> Confirm </b>
                </button>

                <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end of the model -->

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text h3-heading" id="staticBackdropLabel">
            <b v-if="clientModalHeading">{{ clientModalHeading }}</b>
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="edit == 'false' ? addNewClient() : updateClient()">
            <div class="row">
              <div v-if="form.errors">
                <div v-if="form.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ form.errors.message }}
                  </div>
                </div>
              </div>

              <div class="col-xl-7 col-lg-7 col-md-7 col-12">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                    <label> Client Pan</label>
                    <input type="tel" maxlength="10" required v-model="form.pan" class="form-control" placeholder="Pan"
                      name="pan" disabled />

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.pan">{{
                            form.errors.error.pan[0]
                          }}</span></span></small>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                    <label>Mobile No. <small style="color:#f21000">*</small></label>
                    <input maxlenth="10" type="number" class="form-control" v-model="form.phone"
                      placeholder="Mobile Number" required name="phone" />
                    <span style="color: #f21000" id="phoneClient"></span>
                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.phone">{{
                            form.errors.error.phone[0]
                          }}</span></span></small>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                    <label>First Name <small style="color:#f21000">*</small></label>
                    <input type="text" required v-model="form.fname" class="form-control" placeholder="First name"
                      aria-label="First name" name="FirstName" />

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.fname">{{
                            form.errors.error.fname[0]
                          }}</span></span></small>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                    <label>Last Name</label>
                    <input type="text" v-model="form.lname" class="form-control" placeholder="Last name"
                      aria-label="Last name" />

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.lname">{{
                            form.errors.error.lname[0]
                          }}</span></span></small>
                  </div>

                  <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                    <label>Date Of Birth <small style="color:#f21000">*</small></label>
                    <input type="date" v-model="form.dob" required class="form-control" placeholder="DOB" name="dob" />

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.dob">{{
                            form.errors.error.dob[0]
                          }}</span></span></small>
                    <!-- <small style="color:red;" v-if="errors.dob">{{ errors.dob }}</small> -->
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                    <label for="genderClient">Gender <small style="color:#f21000">*</small></label>
                    <select required id="genderClient" v-model="form.gender" class="form-select">
                      <option value="Male">Male</option>
                      <option value="Female">FeMale</option>
                      <option value="Other">Other</option>
                    </select>

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.gender">{{
                            form.errors.error.gender[0]
                          }}</span></span></small>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                    <label for="emailClient">Email <small style="color:#f21000">*</small></label>
                    <input type="email" id="emailClient" required class="form-control" v-model="form.email"
                      placeholder="Email Or UserId" name="email" />

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.email">{{
                            form.errors.error.email[0]
                          }}</span></span></small>
                    <!-- <span style="color:red" v-if="errors.email">{{ errors.email }}</span> -->
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                    <label>Alternate Number</label>
                    <input type="number" class="form-control" v-model="form.alternativePhone"
                      placeholder="Alternate Number" @change="lenthheker($event, 'phoneAltClient')" />
                    <span style="color: #f21000" id="phoneAltClient"></span>

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.alternativePhone">{{
                            form.errors.error.alternativePhone[0]
                          }}</span></span></small>

                  </div>


                  <div class="col-xl-12 col-lg-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                    <label for="statusClient">Status <small style="color:#f21000">*</small></label>
                    <select required id="statusClient" v-model="form.loginStatus" class="form-select">
                      <option value="true">Active</option>
                      <option value="false">InActive</option>
                    </select>

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.status">{{
                            form.errors.error.status[0]
                          }}</span></span></small>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-12 border-3 border-start">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                    <label for="addressLine1">Address line 1 <small style="color:#f21000">*</small></label>
                    <input type="text" required class="form-control" v-model="form.address" placeholder="Address Line-1"
                      id="addressLine1" />
                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.address">{{
                            form.errors.error.address[0]
                          }}</span></span></small>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                    <label for="address2">Address line 2</label>
                    <input type="text" class="form-control" v-model="form.address2" placeholder="Address Line-2" />
                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.address2">{{
                            form.errors.error.address2[0]
                          }}</span></span></small>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                    <label for="inputState">State <small style="color:#f21000">*</small></label>
                    <select required id="inputState" v-model="form.state_id" class="form-select">
                      <option v-for="(state, index) in states" :key="index" :value="state.id">
                        {{ state.name }}
                      </option>
                    </select>
                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.state_id">{{
                            form.errors.error.state_id[0]
                          }}</span></span></small>
                  </div>

                  <div class="col-xl-8 col-lg-8 col-md-8 col-12" style="padding: 5px 5px 5px 5px">
                    <label for="inputCity">City <small style="color:#f21000">*</small></label>
                    <input required type="text" v-model="form.city" class="form-control" id="inputCity"
                      placeholder="City" />
                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.city">{{
                            form.errors.error.city[0]
                          }}</span></span></small>
                  </div>

                  <div class="col-xl-4 col-lg-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px">
                    <label for="pinCodeBox">Pin <small style="color:#f21000">*</small></label>
                    <input required type="number" maxlength="6" v-model="form.pin" class="form-control" id="pinCodeBox"
                      placeholder="Pin" />

                    <small style="color: red" v-if="form.errors"><span v-if="form.errors.error"><span
                          v-if="form.errors.error.pin">{{
                            form.errors.error.pin[0]
                          }}</span></span></small>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" style="margin-top: 10px">
              <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                <b> Confirm </b>
              </button>

              <!-- <button
                type="reset"
                class="btn btn-sm text-white"
                style="background-color: red"
              >
                <b>
                  <font-awesome-icon icon="remove" />
                </b>
                Reset
              </button> -->
              <button type="button" class="btn text-white btn-sm" style="background-color: #f21000"
                data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end of the model -->

  <!-- Modal -->
  <div class="modal fade" id="kycModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Client Kyc</b></h5>
          <button @click="loadClients()" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger p-1 mt-1" role="alert" v-if="msgImgError">
                {{ msgImgError }}
              </div>
            </div>
          </div>

          <form @submit.prevent="
            clientKycVal == 'false' ? clientkycCreate() : clientkycUpdate()
            ">
            <div class="container-fluid table-scroll">
              <table class="table table-hover table-sm">
                <tr class="text table-wrapper-scroll-y rounded-circle tr-head">
                  <th class="text-truncate">+</th>
                  <th class="text-truncate">Document</th>
                  <th class="text-truncate">Action</th>
                </tr>

                <tbody class="text">
                  <tr>
                    <td>
                      <img style="width: 30px; height: 30px" :src="adharFrontImg" alt="" />
                    </td>
                    <td class="text-truncate">
                      <strong class="text">Aadhaar Front</strong>
                      <input type="tel" maxlength="12" minlength="12" @keydown="altPhoneKeydown($event)" required
                        v-model="formKyc.aadharNo" class="form-control" placeholder="Adhar Number" />
                      <span style="color: #f21000" id="clientKycAddar"></span>
                      <small style="color: red" v-if="formKyc.errors"><span v-if="formKyc.errors.error"><span
                            v-if="formKyc.errors.error.aadharNo">{{
                              formKyc.errors.error.aadharNo[0]
                            }}</span></span></small>
                    </td>
                    <td class="text-truncate">
                      <label for="AdharFront">
                        <img style="width: 25px; height: 25px" src="/assets/image/upload.png" />
                      </label>
                      <input @change="uploadAdharFront" class="input-file" style="position: absolute; top: -150000px"
                        id="AdharFront" type="file" accept="image/*" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img style="width: 30px; height: 30px" :src="adharBackImg" alt="" />
                    </td>
                    <td class="text-truncate">
                      <strong class="text">Aadhaar Back</strong>
                    </td>
                    <td class="text-truncate">
                      <label for="AdharBack">
                        <img style="width: 25px; height: 25px" src="/assets/image/upload.png" />
                      </label>
                      <input @change="uploadAdharBack" class="input-file" style="position: absolute; top: -150000px"
                        id="AdharBack" type="file" accept="image/*" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                    </td>
                    <td class="text-truncate">
                      <strong class="text">Pan</strong>
                      <input maxlength="10" minlength="10" type="tel" v-model="formKyc.panNo" class="form-control"
                        placeholder="Pan Number" disabled />
                      <small style="color: red" v-if="formKyc.errors"><span v-if="formKyc.errors.error"><span
                            v-if="formKyc.errors.error.panNo">{{
                              formKyc.errors.error.panNo[0]
                            }}</span></span></small>
                    </td>
                    <td class="text-truncate">
                      <label for="panphoto">
                        <img style="width: 25px; height: 25px" src="/assets/image/upload.png" />
                      </label>
                      <input @change="uploadPanPhoto" class="input-file" style="position: absolute; top: -150000px"
                        id="panphoto" type="file" accept="image/*" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="modal-footer">
              <button @click="loadClients()" type="button" class="btn btn-sm text-white" style="background-color: #f21300"
                data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn text-white btn-sm" style="background-color: #00364f">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>






  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form id="form-modal-todo" class="todo-modal needs-validation" novalidate onsubmit="return false">
          <div class="modal-header align-items-center mb-1" style="background-color: #00364f">
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white">
              <span class="todo-item-favorite cursor-pointer me-75 text-white"><i data-feather="star"
                  class="font-medium-2"></i></span>
              <i data-feather="x" class="cursor-pointer" data-bs-dismiss="modal" stroke-width="3">X</i>
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="code">
                  <label class="form-check-label" for="code">
                    Code
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="name">
                  <label class="form-check-label" for="name">
                    Name
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="email" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="email">
                  <label class="form-check-label" for="email">
                    Email
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="phone" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="phone">
                  <label class="form-check-label" for="phone">
                    Phone
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="pan" class="form-check-input" type="radio"
                    name="flexRadioDefault" id="pan">
                  <label class="form-check-label" for="pan">
                    Pan No.
                  </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio"
                    name="flexRadioDefault" id="all">
                  <label class="form-check-label" for="all">
                    All
                  </label>
                </div>
              </li>
            </ul>


            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <input v-if="filterType != 'all'" :type="(filterType == 'phone') ? 'number' : 'text'" class="form-control"
                v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
            </div>

            <button @click="loadClients()" type="button" class="btn btn-success btn-sm">Success</button>




          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->


  <!-- Modal -->
  <div class="modal fade" id="erningmoneyModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong class="text">Earning</strong>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <table class="table text">
            <thead>
              <tr>
                <th scope="col">Invoice</th>
                <th scope="col">Due Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>₹ {{ this.invoiceValue }}</td>
                <td>₹ {{ this.balance }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm text-white" style="background-color: #f21000" data-bs-dismiss="modal">
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading == true">
    <Spinner />
  </div>
</template>

<script>
import Banner from "../../../components/retailer/comman/Banner.vue";

import Spinner from "../../../components/retailer/comman/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
// import Form from 'vform'
import jQuery from "jquery";
let $ = jQuery;

export default {
  name: "Clients",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      loading: false,
      filterValue: '',
      filterType: '',
      invoiceValue: 0,
      balance: 0,
      clientServices: [],
      msgImgError: "",
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      clientModalHeading: "",
      per_page: 10,

      panNo: "",

      photoImg: this.$store.state.placeholderImg,
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      client_id: null,
      states: [],
      clients: [],
      edit: "false",
      form: {
        photo: "",
        pan: "",
        fname: "",
        lanme: "",
        phone: "",
        email: "",
        dob: "",
        gender: "",
        address: "",
        address2: "",
        alternativePhone: "",
        city: "",
        fatherName: "",
        pin: "",
        state_id: null,
        loginStatus: '',
        code: "",
        errors: {},
      },

      formKyc: {
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors: {}
      },
      noOfProject: [],
      record: {},
      clientKycVal: "false",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      veryfied: "false",
      exist: "false",
      phoneClient: "false",
      phoneAltClient: "false",
      pinCode: "false",
      firstNameClient: "false",
      doBDate: "false",
      clientKycAddar: "false",
    };


  },
  methods: {
    valueNullFilter() {
      this.filterValue = "";
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClients();
    },
    dateCheker(event, type) {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      // console.log(currentDateWithFormat);

      var data = event.target.value;

      if (type == "doBDate") {
        if (currentDateWithFormat < data) {
          this.doBDate = "true";
          console.log(type);
          $("#doBDate").html("<small>It should be less than current date</small>");
        }
        if (currentDateWithFormat >= data) {
          this.doBDate = "false";
          console.log(type);
          $("#doBDate").html("<small></small>");
        }
      }
      // }
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "clientKycAddar") {
        if (data.length != 12) {
          this.clientKycAddar = "true";

          $("#clientKycAddar").html("<small>Aadhar required minimum 12 letters</small>");
        }
        if (data.length == 12) {
          this.clientKycAddar = "false";
          $("#clientKycAddar").html("<small></small>");
        }
      }
      // done
      if (type == "firstNameClient") {
        if (data.length < 3) {
          console.log(data.length);
          this.firstNameClient = "true";
          $("#firstNameClient").html(
            "<small>Profesional Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.firstNameClient = "false";
          $("#firstNameClient").html("<small></small>");
        }
      }

      if (type == "businessName") {
        if (data.length < 3) {
          console.log(data.length);
          this.businessName = "true";
          $("#businessName").html(
            "<small>Profesional Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.businessName = "false";
          $("#businessName").html("<small></small>");
        }
      }
      // done
      if (type == "phoneClient") {
        if (data.length != 10) {
          console.log(data.length);
          this.phoneClient = "true";
          $("#phoneClient").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.phoneClient = "false";
          $("#phoneClient").html("<small></small>");
        }
      }
      // done
      if (type == "phoneAltClient") {
        if (data.length != 10) {
          console.log(data.length);
          this.phoneAltClient = "true";
          $("#phoneAltClient").html("<small>Alt Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.phoneAltClient = "false";
          $("#phoneAltClient").html("<small></small>");
        }
      }

      if (type == "profesionalAddress") {
        if (data.length < 3) {
          console.log(data.length);
          this.profesionalAddress = "true";
          $("#profesionalAddress").html(
            "<small>Address required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.profesionalAddress = "false";
          $("#profesionalAddress").html("<small></small>");
        }
      }
      if (type == "clientCity") {
        if (data.length < 3) {
          console.log(data.length);
          this.profesionalCity = "true";
          $("#profesionalCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.profesionalCity = "false";
          $("#profesionalCity").html("<small></small>");
        }
      }

      // done
      if (type == "pinCode") {
        if (data.length != 6) {
          console.log(data.length);
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          console.log(data.length);
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }


      if (type == "dobDate") {

        var currentDate = new Date();
        data = this.dateTime(data)
        currentDate = this.dateTime(currentDate);
        if (data > currentDate) {
          this.dobDate = "true";

          $("#dobDate").html("<small> Enter Valid Date</small>");
        } else {
          this.dobDate = "false";
          $("#dobDate").html("<small></small>");
        }
      }
    },
    clientBusinessName(businessClients) {
      var name;
      // console.log(businessClients);
      if (businessClients.length > 0) {
        businessClients.forEach((value, index) => {
          // console.log(value.business.name);
          index;
          name = value.business.name;
        });
      }

      return name;
    },
    leadClientServicesForClient(client) {
      this.invoiceValue =
        parseInt(client.clientServiceTotal) + parseInt(client.clientServiceGst);
      this.balance = this.invoiceValue - parseInt(client.clientPaymentTotal);
    },
    submit() {
      // alert("Hiiii.");
    },
    valueNull() {
      this.panNo = "";
      this.veryfied = 'false'
      this.exist = 'false'
    },
    findRecordeByPanId() {
      this.form = {};
      this.loading = true
      this.$axios
        .get(`retailer/client?pan=${this.panNo}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.record = res.data.data.data[0];
          if (res.data.data.data[0]) {
            this.exist = "true";
            this.veryfied = "false";
            this.form = this.record;
            this.clientModalHeading = "Edit Client";
            // this.$router.push({name: 'Itr2'})
          } else {
            // alert("by");
            this.$axios
              .post(
                "retailer/verifypan",
                { panNo: this.panNo },
                { headers: { Authorization: "Bearer " + localStorage.accessToken } }
              )
              .then((res) => {

                // console.log(res.data.data)
                if (res.data.data.data.status == 'VALID') {
                  toast.success(" Pan Verrifiyed  Succesfully", {
                    autoClose: 1000,
                  });


                  this.form.fname = res.data.data.data.first_name;
                  this.form.lname = res.data.data.data.last_name;


                  this.form.pan = this.panNo;
                  this.clientModalHeading = "Add Client";
                  $("#panVerifiy").modal("hide");
                  $("#staticBackdrop").modal("show");



                } else {
                  // console.log('-----invalid pan--')
                  this.veryfied = "invalid";
                  this.exist = "false";
                }
              })
              .catch((error) => {
                this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
              });

          }
        })
        .finally(() => (this.loading = false));
    },

    // ------------------------------------------------------------

    uploadPhoto(e) {
      this.photoImg = URL.createObjectURL(e.target.files["0"]);
      this.form.photo = e.target.files["0"];
    },
    uploadAdharFront(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Front File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharFront").value;
        this.extensionkycAdharFront = fileName.split(".").pop();

        if (
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "png" ||
          this.extensionkycAdharFront == "PDF" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "PNG"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharFrontPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      }
    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Back  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharBack").value;
        this.extensionkycAdharBack = fileName.split(".").pop();

        if (
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "png" ||
          this.extensionkycAdharBack == "PDF" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "PNG"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.aadharBackPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG , PNG , PDF, JPEG ";
        }

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      }
    },
    // formCloseKhali(){
    // this.formKyc = {}
    // this.adharBackImg = this.$store.state.placeholderImg;
    //   this.adharFrontImg = this.$store.state.placeholderImg;
    //   this.panImg = this.$store.state.placeholderImg;
    // },
    uploadPanPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Pan File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#panphoto").value;
        this.extensionkycPanPhoto = fileName.split(".").pop();

        if (
          this.extensionkycPanPhoto == "pdf" ||
          this.extensionkycPanPhoto == "jpg" ||
          this.extensionkycPanPhoto == "jpeg" ||
          this.extensionkycPanPhoto == "png" ||
          this.extensionkycPanPhoto == "PDF" ||
          this.extensionkycPanPhoto == "JPG" ||
          this.extensionkycPanPhoto == "JPEG" ||
          this.extensionkycPanPhoto == "PNG"

        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.formKyc.panPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG , PNG , PDF, JPEG ";
        }

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      }
    },
    clientkycValueAsign(value) {
      this.formKyc.aadharNo = ""
      this.formKyc.panNo = ""
      this.formKyc = {}
      this.formKyc.errors = {}


      this.adharFrontImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.panImg = this.$store.state.placeholderImg;
      this.client_id = value.id;



      if (value.client_kyc) {
        this.formKyc = value.client_kyc
        console.log(value.client_kyc)
      }



      if (value.client_kyc == null) {
        // alert('clientkycCreate')
        this.kycModalHeading = "Create client Kyc";
        this.clientKycVal = "false";
        // this.clientkycCreate()
      } else {
        // alert('edit')
        this.kycModalHeading = "Update client Kyc";
        this.formKyc = value.client_kyc;

        if (this.formKyc.panPhoto) {
          if (this.formKyc.panPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.client_id +
              "/" +
              value.client_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.client_id +
                "/" +
                value.client_kyc.panPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.client_id +
              "/thumbs/" +
              value.client_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.client_id +
                "/thumbs/" +
                value.client_kyc.panPhoto;
            }

            this.extensionkycPanPhoto = this.panImg.split(".").pop();

            if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        }

        if (this.formKyc.passbookPhoto != null) {
          this.passBookImg =
            this.$store.state.imgUrl +
            "/client/" +
            this.client_id +
            "/thumbs/" +
            value.client_kyc.passbookPhoto;
          this.formKyc.passbookPhoto = this.passBookImg;
          //   alert(this.passBookImg)
        } else {
          this.passBookImg = this.$store.state.placeholderImg;
        }

        if (this.formKyc.aadharFrontPhoto) {
          if (this.formKyc.aadharFrontPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.client_id +
              "/" +
              value.client_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.client_id +
                "/" +
                value.client_kyc.aadharFrontPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.client_id +
              "/thumbs/" +
              value.client_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.client_id +
                "/thumbs/" +
                value.client_kyc.aadharFrontPhoto;
            }

            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }
        }

        if (this.formKyc.aadharBackPhoto) {
          if (this.formKyc.aadharBackPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.client_id +
              "/" +
              value.client_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.client_id +
                "/" +
                value.client_kyc.aadharBackPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.client_id +
              "/thumbs/" +
              value.client_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.client_id +
                "/thumbs/" +
                value.client_kyc.aadharBackPhoto;
            }

            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }
        }

        this.clientKycVal = "true";
        // alert('done')
      }
    },

    clientkycCreate() {
      if (this.msgImgError == "" && this.clientKycAddar == "false") {
        const formData = new FormData();
        const keys = Object.keys(this.formKyc);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.formKyc[keys[n]] !== null) {
            formData.append(keys[n], this.formKyc[keys[n]]);
          }
        }
        formData.append("client_id", this.client_id);
        // console.log(formData)
        // alert('befor client kyc create ')
        this.loading = true;
        this.$axios
          .post("retailer/clientkyc", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data.data;

            toast.success("Kyc Created Succesfully", {
              autoClose: 1000,
            });
            $("#kycModal").modal("hide");
            this.formKyc = {};
            this.loadClients();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.formKyc.errors = error.response.data;
          })
      }
    },

    clientkycUpdate() {
      if (this.msgImgError == "" && this.clientKycAddar == "false") {
        const formData = new FormData();
        const keys = Object.keys(this.formKyc);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.formKyc[keys[n]] !== null) {
            formData.append(keys[n], this.formKyc[keys[n]]);
          }
        }
        formData.append("client_id", this.client_id);
        this.loading = true;
        this.$axios
          .post(`retailer/clientkyc/${this.formKyc.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data.data;
            toast.success("Kyc Updated Succesfully.", {
              autoClose: 1000,
            });
            $("#kycModal").modal("hide");
            this.formKyc = {};
            this.loadClients();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.formKyc.errors = error.response.data;
          })
      }
    },

    loadState() {
      this.$axios
        .get("retailer/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data)
          this.states = res.data.data;
          // console.log(this.states)
        });
    },
    valueAssignClientForKyc(client) {

      this.msgImgError = ""
      console.log(client)
      // this.formKyc.panPhoto = ""
      // this.formKyc.aadharBackPhoto = ""
      // this.formKyc.aadharFrontPhoto = ""
      this.formKyc = {}
      this.formKyc.errors = {}
      this.clientKycVal = "false";

      this.adharBackImg = this.$store.state.placeholderImg;
      this.adharFrontImg = this.$store.state.placeholderImg;
      this.panImg = this.$store.state.placeholderImg;

      if (client.client_kyc) {
        this.clientKycVal = "true";
        this.formKyc = client.client_kyc



        // aadhar front 

        if (client.client_kyc.aadharFrontPhoto) {
          if (
            this.$store.state.imgUrl +
            "/client/" +
            client.id +
            "/" +
            client.client_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/client/" +
              client.id +
              "/" +
              client.client_kyc.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/client/" +
            client.id +
            "/thumbs/" +
            client.client_kyc.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/client/" +
              client.id +
              "/thumbs/" +
              client.client_kyc.aadharFrontPhoto;
          }

          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }


        // aadhar back


        if (client.client_kyc.aadharBackPhoto) {
          if (
            this.$store.state.imgUrl +
            "/client/" +
            client.id +
            "/" +
            client.client_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/client/" +
              client.id +
              "/" +
              client.client_kyc.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/client/" +
            client.id +
            "/thumbs/" +
            client.client_kyc.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/client/" +
              client.id +
              "/thumbs/" +
              client.client_kyc.aadharBackPhoto;
          }

          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }




        // pen photo 
        if (client.client_kyc.panPhoto) {
          if (
            this.$store.state.imgUrl +
            "/client/" +
            client.id +
            "/" +
            client.client_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/client/" +
              client.id +
              "/" +
              client.client_kyc.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/client/" +
            client.id +
            "/thumbs/" +
            client.client_kyc.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/client/" +
              client.id +
              "/thumbs/" +
              client.client_kyc.panPhoto;
          }

          this.extensionkycPanPhoto = this.panImg.split(".").pop();

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }





      }

    },
    valueAssignClient(client) {

      this.form = {};
      this.form.errors = {}

      this.form = client;
      this.form.loginStatus = client.loginStatus
      this.client_id = client.id;
      this.edit = "true";
      this.clientModalHeading = "Edit Client";


      // console.log(this.form)
    },
    updateClient() {
      if (
        this.phoneClient == "false" &&
        this.phoneAltClient == "false" &&
        this.pinCode == "false" &&
        this.firstNameClient == "false" &&
        this.doBDate == "false"
      ) {
        this.loading = true;
        this.$axios
          .put(`retailer/client/${this.client_id}`, this.form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;
            // console.log(res.data)

            toast.success(" Client Updated successfully.", {
              autoClose: 1000,
            });

            $("#staticBackdrop").modal("hide");
            this.form = {};
            this.form.errors = {}
            this.loadClients();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.form.errors = error.response.data;
          })

      }
    },
    addNewClient() {
      if (
        this.phoneClient == "false" &&
        this.phoneAltClient == "false" &&
        this.pinCode == "false" &&
        this.firstNameClient == "false" &&
        this.doBDate == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post(`retailer/client`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            // alert("api");
            res.data;
            // console.log(res.data)

            toast.success(" Client Created Successfully.", {
              autoClose: 1000,
            });
            $("#staticBackdrop").modal("hide");
            this.form = {};
            this.loadClients();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.form.errors = error.response.data;
          })


      }
    },
    deleteClient(id) {
      this.loading = true;
      this.$axios
        .delete(`retailer/client/${id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;
          // console.log(res.data)
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "The Client Deleted successfully.",
            showConfirmButton: false,
            timer: 1500,
          });

          this.loadClients();
        })
        .finally(() => (this.loading = false));
    },
    altPhoneKeydown(e) {
      // Check if the pressed key is a non-numeric character
      if (!/^\d$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete") {
        // If a non-numeric character is detected, display a message
        $("#altPhone").html("<small>Only numeric characters are allowed</small>");
        // Prevent the default behavior of the key press
        e.preventDefault();
      }
    },
    loadClients(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {

        if (this.filterType == 'all') {
          this.filterValue = ''
          this.filterType = ''
        }


        if (this.filterValue != "" && this.filterType != "") {

          pageUrl += `retailer/client?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
        } else {
          pageUrl += `retailer/client?per_page=${this.per_page}`;
        }

      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.clients = res.data.data.data;
          this.pagination = res.data.data;



          this.filterValue = ""
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle() {
      document.title = this.$store.state.retailerPageTitles.client
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.loadState();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.counter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 30px 50px;
}

.counter {
  font-size: 60px;
  margin-top: 10px;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}

.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}





.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
